/**
 * FormatDate will format input date and return in date format of 1-1-2022.
 */

import { t } from 'i18next';
import moment from 'moment';

export const formatDate = (date: string): string => {
  const selectedDate = new Date(date);
  const mm = selectedDate.getMonth() + 1;
  const dd = selectedDate.getDate();
  const yyyy = selectedDate.getFullYear();

  return `${dd}-${mm}-${yyyy}`;
};

export const formatTimeDate = (dateTime: Date): string => {
  const modifiedDateTime = dateTime
    .toString()
    .replace('T', ' ')
    .replace('Z', ' ')
    .replaceAll('-', '.')
    .slice(0, -4);

  return modifiedDateTime;
};

// * Formats a date string into a more readable format.
export const prettifyDate = (dateStr: string): string => {
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  return date.toLocaleString('en-US', options);
};
/**
 * Calculates time difference in user friendly format. for example: 10h 12m
 */
export function getTimeDifference(startTime: string, endTime: string): string {
  try {
    /**
     * Time difference in miliseconds
     */
    const msDiff = new Date(endTime).getTime() - new Date(startTime).getTime();
    /**
     * Time difference in minutes
     */
    const mDiff = Math.floor(msDiff / 1000 / 60);

    const hours = Math.floor(mDiff / 60);
    const minutes = mDiff - hours * 60;

    if (!!hours && !!minutes) {
      return `${hours}h ${minutes}m`;
    }

    if (!!hours && !minutes) {
      return `${hours}h`;
    }

    return `${minutes}m`;
  } catch (e) {
    console.error('Error when calculating time diff:', e);
    return 'n/a';
  }
}
export const getEnteredAtDate = (
  data: string | undefined,
  timezone: string,
): Date | undefined => {
  if (!data) return;
  const utcEntryTime = new Date(data).toISOString();

  const offset = new Date(utcEntryTime).getTimezoneOffset();

  const m = moment(utcEntryTime).tz(timezone);
  m.add(m.utcOffset() + offset, 'minutes');
  const newEntryTime = new Date(m.utc().format());
  return newEntryTime;
};

export const getExitedAtDate = (
  data: string | undefined,
  timezone: string,
): Date | undefined => {
  if (!data) return;
  const utcExitTime = new Date(data).toISOString();

  const offset = new Date(utcExitTime).getTimezoneOffset();

  const m = moment(utcExitTime).tz(timezone);
  m.add(m.utcOffset() + offset, 'minutes');
  const newExitTime = new Date(m.utc().format());
  return newExitTime;
};
export const getFormattedDateTime = (
  dateTime: string | undefined,
  timezone: string,
): string => {
  if (!dateTime) {
    return 'n/a';
  }
  const timeZonned = timezone
    ? moment(dateTime).tz(timezone)
    : moment(dateTime);
  return (
    timeZonned.format('DD.MM.YYYY ') + t('at') + timeZonned.format(' HH:mm')
  );
};
