import { TranslatableString } from './no';

const fiJSON: { [key in TranslatableString]: string } = {
  //Languages
  'Language switcher': 'Kielen vaihtaja',
  English: 'Englanti',
  Norwegian: 'Norjan kieli',
  Finnish: 'Suomalainen',
  Swedish: 'Ruotsin kieli',

  //login
  'Sign in': 'Kirjaudu sisään',
  'Email address': 'Sähköpostiosoite',
  Password: 'Salasana',
  'Remember me': 'Kom ihåg mig',
  'Lost password?': 'Kadonnut Salasana?',
  'The email provided should be a valid email address':
    'Annetun sähköpostiosoitteen tulee olla voimassa oleva sähköpostiosoite',
  'The email field is required': 'Sähköpostikenttä on pakollinen',
  'The password field is required': 'Salasanakenttä on pakollinen',
  'Fill in the fields below to sign into your account.':
    'Täytä alla olevat kentät kirjautuaksesi tilillesi.',

  //Menu
  Overview: 'Yleiskatsaus',
  Dashboard: 'Kojelauta',
  Analytics: 'Analytics',
  Financial: 'Taloudellinen',
  Management: 'Hallinto',
  Monitoring: 'Valvonta',
  'Camera feed': 'Kameran syöte',
  Facilities: 'Palvelut',
  Pricing: 'Hinnoittelu',
  Matching: 'Vastaava',
  'Parking sessions': 'Pysäköintitunnit',
  Allowlist: 'Sallitut lista',
  'Event log': 'Tapahtumaloki',
  Subscriptions: 'Asiakkaat',
  Customers: 'Asiakkaat',
  'End-Users': 'Loppukäyttäjät',
  Active: 'Aktiivinen',
  Deleted: 'Poistettu',
  Team: 'Tiimi',
  Help: 'auta',
  FAQ: 'FAQ',
  Support: 'Tuki',
  Documentation: 'Dokumentointi',
  Configuration: 'Kokoonpano',
  Operators: 'Operaattorit',
  'ID-carriers': 'ID-operaattorit',
  System: 'Järjestelmä',
  Users: 'Käyttäjät',
  'View details': 'Näytä tiedot',
  SubOperators: 'Alaoperaattorit',
  Logout: 'Kirjautua ulos',

  'Search for license plate, user, transaction etc.':
    'Etsi rekisterikilpi, käyttäjä, tapahtuma jne.',

  'Search here': 'Search here',
  'Recent searches': 'Recent searches',
  'Saved searches': 'Saved searches',
  'User results': 'User results',
  'Licence plates & vehicles': 'Licence plates & vehicles',

  //Dashboard
  'All facilities': 'Kaikki tilat',
  Period: 'Kausi',
  Today: 'Tänään',
  Yesterday: 'Eilen',
  'This week': 'Tämä viikko',
  'Last week': 'Viime viikko',
  'This month': 'Tässä kuussa',
  'Last month': 'Viime kuukausi',
  'Custom period': 'Mukautettu ajanjakso',
  'All time': 'Koko ajan',

  'Packing facility': 'Pakkausmahdollisuus',
  Occupancy: 'Käyttöaste',
  'Facility overview': 'Laitteiston yleiskatsaus',
  'Facility area': 'Tila-alue',
  'In order to see the ocuppancy percent, you need to fill in the capacity field':
    'Nähdäksesi käyttöasteen, sinun on täytettävä kapasiteettikenttä',
  Revenue: 'Tulot',
  Sales: 'Myynti',
  up: 'ylös',
  down: 'alas',
  View: 'Näytä',
  'n/a': 'n/a',

  //Analytics Page
  'Occupancy & sessions': 'Käyttöaste ja istunnot',
  'Average occupancy': 'Keskimääräinen käyttöaste',
  'Average session': 'Keskimääräinen istunto',
  Sessions: 'Istunnot',
  'in total': 'yhteensä',
  weekly: 'viikoittain',
  daily: 'päivittäin',
  'per hour': 'tunnissa',
  'Compared to..': 'Verrattuna..',
  'from last month': 'viime kuusta',
  'settlement types/cashflow': 'selvitystyypit/kassavirta',
  'On location': 'Kohteessa',
  Invoice: 'Lasku',
  Online: 'verkossa',
  Unpaid: 'Palkaton',
  Income: 'Tulo',
  'Yearly income (estimated)': 'Vuositulot (arvioitu)',
  'Per month': 'Kuukaudessa',
  'Per year': 'Vuodessa',
  'Per day': 'Päivässä',
  'Per hour': 'Tunnissa',
  'Active subscribers': 'Aktiiviset tilaajat',
  'Subscription revenue': 'Tilaustulot',
  'Chunk rate': 'Osuusprosentti',
  Description: 'Kuvaus',

  //Facility access
  'Facility access': 'Toimitiloihin pääsy',
  'Search in LP,facilities or group name...':
    'Hae LP:stä, tiloista tai ryhmän nimestä...',
  'Add new': 'Lisää uusi',
  'Vehicle group': 'Ajoneuvoryhmä',
  Status: 'Tila',
  Actions: 'Toiminnot',
  None: 'Ei mitään',
  'Choose vehicle groups': 'Valitse ajoneuvoryhmät',

  'View allowlist': 'Näytä sallittujen luettelo',
  'Edit allowlist': 'Muokkaa sallittujen luetteloa',
  'Delete allowlist': 'Poista sallittujen luettelo',

  '"Syncing" or "Out of sync" lists may take a few minutes to be synced. To get the latest status refresh:':
    '"Synkronointi"- tai "Ei synkronoitu" -luetteloiden synkronointi voi kestää muutaman minuutin. Näin saat uusimman tilapäivityksen:',

  facilities: 'tilat',
  Add: 'Lisätä',
  Cancel: 'Peruuttaa',
  Close: 'Sulkea',
  'Add allowlist': 'Lisää sallittujen luettelo',
  'Add vehicles': 'Lisää ajoneuvoja',
  'License plates': 'Rekisterikilvet',
  or: 'tai',
  'Import from CSV': 'Tuo CSV-tiedostosta',
  'Choose facilities': 'Valitse tilat',
  'All facilitites': 'Kaikki tilat',
  'View group': 'Näytä ryhmä',
  'Group name': 'Ryhmän nimi',
  Delete: 'Poistaa',
  'No facilities': 'Ei tiloja',
  Edit: 'Muokata',
  Resolution: 'Resoluutio',
  Hourly: 'Tunneittain',
  Daily: 'Päivittäin',
  'Subscription revenue trend': 'Tilaustulojen kehitys',
  'No results for': 'Ei tuloksia haulle',

  //update
  'Search operator...': 'Hakuoperaattori...',
  'Search...': 'Hae...',
  Name: 'Nimi',
  'Account Type': 'Tilin tyyppi',
  Email: 'Sähköposti',
  phone: 'puhelin',
  Account: 'Tili',
  'Mobile phone': 'Kännykkä',
  User: 'Käyttäjä',
  'User type': 'Käyttäjätyyppi',
  Created: 'Luotu',
  'Last login': 'Viimeinen kirjautuminen',
  Operator: 'Operaattori',
  'Subscription package': 'Tilauspaketti',
  Vehicles: 'Ajoneuvot',
  'Subscription period': 'Tilausaika',
  'Subscription code': 'Tilauskoodi',
  Price: 'Hinta',
  Facility: 'Laitos',
  'Car park': 'Parkkipaikka',
  'Parking started': 'Pysäköinti alkoi',
  'Parking ended': 'Pysäköinti päättyi',
  'Internal ID': 'Sisäinen tunnus',
  'Parking attribute': 'Pysäköintiominaisuus',
  ID: 'ID',
  Registration: 'Rekisteröinti',
  'Search in name,date,category,location or details...':
    'Hae nimellä, päivämäärällä, kategorialla, sijainnilla tai tiedoilla...',
  'Date & time': 'Treffiaika',
  Catagory: 'Luokka',
  Location: 'Sijainti',
  Details: 'Yksityiskohdat',
  Warnings: 'Varoitukset',
  'SwappAccess Admin Dashboard': 'SwappAccess Admin Dashboard',
  'Probability rate': 'Todennäköisyysprosentti',
  'Unread license plates': 'Lukemattomat rekisterikilvet',
  'Previous period': 'Edellinen kausi',
  'Area(Tariff ID)': 'Alue (tariffitunnus)',
  'Search in name, subscription ID or subscription article code ...':
    'Hae nimellä, tilaustunnuksella tai tilausartikkelikoodilla...',
  'Search by name,role,email...': 'Hae nimellä, roolilla, sähköpostilla...',

  'Email Address': 'Sähköpostiosoite',
  'The site is currently down for maintenance':
    'Sivusto on tällä hetkellä pois käytöstä huollon vuoksi',
  'We apologize for any inconveniences caused':
    'Pahoittelemme mahdollisesti aiheutunutta haittaa',
  Phone: 'Puhelin',

  "The page you were looking for doesn't exist.":
    'Etsimääsi sivua ei ole olemassa.',

  "It's on us, we moved the content to a different page. The search below should help!":
    'Se on meidän vastuullamme, siirsimme sisällön toiselle sivulle. Alla olevan haun pitäisi auttaa!',
  'Search terms here...': 'Hakutermit täältä...',
  Search: 'Hae',

  'Go back': 'Mene takaisin',
  'Go to homepage': 'Mene kotisivulle',
  'There was an error, please try again later':
    'Tapahtui virhe. Yritä myöhemmin uudelleen',
  'The server encountered an internal error and was not able to complete your request':
    'Palvelimessa tapahtui sisäinen virhe, eikä se voinut suorittaa pyyntöäsi',

  'Refresh view': 'Päivitä näkymä',
  'Tokyo White React Typescript Admin Dashboard':
    'Tokyo White React Typescript Admin Dashboard',
  'High performance React template built with lots of powerful MUI (Material-UI) components across multiple product niches for fast & perfect apps development processes.':
    'Suorituskykyinen React-malli, joka on rakennettu monilla tehokkailla MUI (Material-UI) -komponenteilla useissa tuoteraoissa nopeita ja täydellisiä sovellusten kehitysprosesseja varten.',
  'There is no data available for this graph':
    'Tälle kaaviolle ei ole saatavilla tietoja',
  'Coming soon': 'Tulossa pian',
  "There's still work to be done on this feature, before you'll be able to see it.":
    'Tämän ominaisuuden parissa on vielä tehtävää, ennen kuin näet sen.',
  'Weekly revenue': 'Viikkotulot',
  'Reports for this weeks revenue stream': 'Raportit tämän viikon tulovirrasta',
  EasyPark: 'EasyPark',
  Microlog: 'Microlog',
  'View complete report': 'Katso täydellinen raportti',
  Alerts: 'Hälytykset',
  'Camera status': 'Kameran tila',
  'Barrier status': 'Esteen tila',
  'Ticket machine status': 'Lippukoneen tila',
  On: 'Päällä',
  Off: 'Vinossa',
  'Skip menu filtering': 'Ohita valikon suodatus',
  'Last year': 'Viime vuonna',
  'Traffic overview': 'Liikennekatsaus',
  'License plate': 'Rekisterikilpi',
  Date: 'Päivämäärä',
  Started: 'Aloitettu',
  Ended: 'Päättyi',
  Duration: 'Kesto',
  'Subscriptions active / Max': 'Tilaukset aktiiviset / maks',
  Affinity: 'Affiniteetti',
  'Delete user': 'Poista käyttäjä',
  Role: 'Rooli',
  Save: 'Tallentaa',
  'Per subscription': 'Tilausta kohti',
  'Last updated': 'Viimeksi päivitetty',
  'Something went wrong': 'Jotain meni pieleen',
  'Group name is a required field': 'Ryhmän nimi on pakollinen kenttä',
  'Vehicle groups': 'Ajoneuvoryhmät',
  'Edit vehicle group': 'Muokkaa ajoneuvoryhmää',
  'View vehicle group': 'Näytä ajoneuvoryhmä',
  'Add license plates': 'Lisää rekisterikilvet',
  'Session trend': 'Istunnon trendi',
  Transactions: 'Tapahtumat',
  Contracts: 'Sopimukset',

  'Forgot password': 'Unohtuiko salasana',
  'Enter your email address below and we will send you a password reset link':
    'Kirjoita sähköpostiosoitteesi alle, niin lähetämme sinulle salasanan palautuslinkin',
  Submit: 'Lähetä',

  'Reset password': 'Nollaa salasana',
  'Write and confirm your new password below':
    'Kirjoita ja vahvista uusi salasanasi alle',
  'New password': 'Uusi salasana',
  'Confirm password': 'Vahvista salasana',
  'New password field is required': 'Uusi salasanakenttä vaaditaan',
  'Confirm password field is required':
    'Vahvista salasana -kenttä on pakollinen',
  'Password confirmation must be the same as the password field':
    'Salasanan vahvistuksen on oltava sama kuin salasanakentässä',
  'We cannot connect to the backend at this point. We apologize for the inconvenience':
    'Vi kan inte ansluta till backend vid det här laget. Vi ber om ursäkt för besväret',
  Cameras: 'Kamerat',
  'Register camera': 'Rekisteröidä kamera',
  'Available cameras': 'Saatavilla kamerat',
  'Installed cameras': 'Installed Cameras',
  'Register lane': 'Rekisteröidä',
  'Add lane': 'Lisää kaista',
  'Adding lane': 'Lisää kaistaa',
  'Select type': 'Valitse tyyppi',
  'Serial No.': 'Sarjanumero.',
  'IP address': 'IP-osoite',
  Username: 'Käyttäjänimi',
  Notes: 'Muistiinpanot',
  'The name field is required': 'Nimikenttä vaaditaan',
  'The username field is required': 'Käyttäjätunnuskenttä vaaditaan',
  'The Serial No. field is required': 'Sarjanumero -kenttä vaaditaan',
  'The IP address field is required': 'IP -osoitekenttä vaaditaan',
  'The notes field is required': 'Muistiinpanokenttä vaaditaan',
  'Direction is required': 'Suunta vaaditaan',
  'Country is required': 'maa vaaditaan',
  'Please provide valid IP address': 'Anna kelvollinen IP -osoite',
  'Assigned to lane': 'Osoitettu kaistalle',
  'New camera registration': 'Uuden kameran rekisteröinti',
  'Add entry lane': 'Lisää sisääntulokaista',
  'Add exit lane': 'Lisää poistumiskaista',
  Entry: 'Sisäänpääsy',
  Exit: 'Poistu',
  Lanes: 'Kaistat',
  'At least one type must be selected': 'Vähintään yksi tyyppi on valittava',
  Connect: 'Määrittää',
  'Select front camera': 'Valitse etukamera',
  'Select back camera': 'Valitse takakamera',

  'Front camera': 'Etukamera',
  'Back camera': 'Takakamera',

  'New entry lane': 'Uusi sisääntulokaista',
  'New exit lane': 'Uusi poistumiskaista',

  'Verification code from email': 'Vahvistuskoodi sähköpostista',
  'Verification code field is required': 'Vahvistuskoodikenttä on pakollinen',
  'We have sent you an email with a code fore resetting password. Please add the code below':
    'Olemme lähettäneet sinulle sähköpostin, jossa on salasanan nollauskoodi. Lisää koodi alle',

  'Delete lane': 'Poista kaista',

  "Are you sure you want to delete the lane '{{lane}}'?":
    "Haluatko varmasti poistaa kaistan '{{lane}}'?",

  Yes: 'Joo',
  No: 'Ei',
  'Upload image': 'Lataa kuva',

  'Access terminals': 'Pääsy terminaaleihin',
  'Add access terminal': 'Lisää pääsypääte',
  Prompt: 'Kehote',

  Port: 'Portti',
  Camera: 'Kamera',
  'The Port field is required': 'Portti-kenttä on pakollinen',
  'The registration number is required': 'Rekisterinumero vaaditaan',
  'Access terminal': 'Pääsypääte',
  'Connected terminal': 'Liitetty pääte',
  'Edit camera': 'Muokata kameraa',

  "Are you sure you want to delete the camera '{{camera}}' ?":
    "Haluatko varmasti poistaa kameran '{{camera}}'?",
  'Delete camera': 'Poista kamera',

  'Edit access terminal': 'Muokkaa pääsypäätettä',
  "Are you sure you want to delete the access terminal '{{accessTerminal}}' ?":
    "Haluatko varmasti poistaa käyttöpäätteen '{{accessTerminal}}'?",
  'Replace image': 'Vaihda kuva',

  'Background color': 'Taustaväri',
  'Text color': 'Tekstin väri',

  'Hide heading': 'Piilota otsikko',
  'Hide terminal name': 'Piilota terminaalin nimi',

  Relays: 'Releet',
  'Register relay': 'Rekisteröidä rele',
  'New relay registration': 'Uusi releen rekisteröinti',
  Uninstall: 'Poista asennus',
  'Uninstall access terminal': 'Poista käyttöpääte',
  'This action will completely delete the access terminal. Would to like to remove it? yes/no':
    'Tämä toiminto poistaa pääsypäätteen kokonaan. Haluatko poistaa sen? kyllä ​​ei',

  'Update connection': 'Päivitä yhteys',
  'Edit connection': 'Muokkaa yhteyttä',
  'Edit IP': 'Muokkaa IP-osoitetta',
  'Edit relay': 'Muokata relettä',
  'Delete relay': 'Poistaa rele',
  Remove: 'Poista',
  'Terminal details': 'Terminaalin tiedot',
  'Are you sure you want to delete the Relay':
    'Haluatko varmasti poistaa välityksen',
  'Camera details': 'Kameran tiedot',
  'Relay details': 'Releen tiedot',

  Relay: 'Rele',
  'Access terminal connection': 'Pääsyterminaaliliitäntä',
  'Relay port': 'Rele portti',
  Connection: 'Yhteys',

  'Unexpected error in backend. [{{errorStatus}}]':
    'Odottamaton virhe taustaohjelmassa. [{{errorStatus}}]',

  OK: 'Okei',
  'Camera unreachable': 'Kameraan ei saada yhteyttä',
  NetworkRelayUnreachable: 'Verkkorele ei tavoitettavissa',
  'Camera error': 'Kameravirhe',
  Unknown: 'Tuntematon',
  'Network relay error': 'Verkkorelevirhe',
  'Network relay unreachable': 'Verkkorele ei ole tavoitettavissa',
  Type: 'Tyyppi',
  'Access url': 'Pääsy URL -osoitteeseen',
  'Disconnect camera': 'Irrota kamera',
  'Disconnect relay': 'Irrottaa rele',
  'Are you sure you want to disconnect the {{deviceName}} - {{ipAddress}} {{deviceType}}?':
    'Oletko varma, että haluat irrottaa {{deviceName}} - {{iPaddress}} {{deviceType}}?',

  'Select date': 'Valitse päivämäärä',
  'Ended at': 'Päättyi klo',
  'Started at': 'Alkoi klo',
  'Select facility': 'Valitse laitos',

  'Search by license plate…': 'Hae rekisterikilven perusteella…',
  'Start date range': 'Aloita ajanjakso',
  'End date range': 'Lopeta ajanjakso',

  at: 'kl.',

  Direction: 'Suunta',
  Issues: 'Issues',
  Solve: 'Ratkaista',
  'Registered issues in': 'Rekisteröityneet numerot sisään',

  Country: 'maassa',
  Confirm: 'vahvistaa',
  Drop: 'Pudota',
  Keep: 'Pitää',
  'Enable ANPR': 'Ota ANPR käyttöön',
  'Disable ANPR': 'Poista ANPR käytöstä',
  'Send ANPR events': 'Lähetä ANPR-tapahtumat',
  'Are you sure you want to logout?':
    'Oletko varma, että haluat kirjautua ulos?',
  'Flagged parkings': 'Liituttu parkkipaikat',
  'Drop this flagged parking?': 'Pudota tämä merkitty pysäköintialue?',
  'Drop this flagged vehicle?': 'Pudota tämä liputettu ajoneuvo?',
  In: 'Sisään',
  Out: 'Ulos',
  Norway: 'Norja',

  'Install kiosks': 'Asenna kioskeja',
  Install: 'Asentaa',
  'Edit kiosk': 'Muokkaa kioskia',

  'Parking session': 'Pysäköinti-istunto',
  'Entry time': 'Sisääntuloaika',
  'Exit time': 'Poistumisaika',

  'Before trying to resolve issues in flagged parking, try resolving all the issues in':
    'Ennen kuin yrität ratkaista merkityn pysäköintialueen ongelmia, yritä ratkaista kaikki ongelmat',
  'Fixing those is very likely to fix these issues too.':
    'Niiden korjaaminen korjaa todennäköisesti myös nämä ongelmat.',
  'Solve recognition issue': 'Ratkaise tunnistusongelma',
  'Registration number': 'Rekisterinumero',
  Configure: 'Määritä',
  'Membership plans': 'Jäsenyyssuunnitelmat',
  'Edit membership plan': 'Muokkaa jäsensuunnitelmaa',
  'Add membership plan': 'Lisää jäsenyyssuunnitelma',
  'Company name': 'Yrityksen nimi',
  'Period days': 'Jakson päivät',
  Memberships: 'Jäsenyydet',
  'Plan name': 'Suunnitelman nimi',
  Comments: 'Kommentit',
  'Are you sure you want to approve': 'Oletko varma, että haluat hyväksyä?',
  'Are you sure you want to reject': 'Oletko varma, että haluat hylätä?',
  'Sales period': 'Myyntikausi',
  'Max concurrent uses': 'Max samanaikainen käyttö',
  'Vat rate': 'ALV-prosentti',
  Action: 'Toiminta',
  private: 'yksityinen',
  'Are you sure you want to make': 'Oletko varma, että haluat tehdä',
  public: 'julkinen',
  'Are you sure you want to': 'Oletko varma, että haluat',
  publish: 'julkaista',
  'Are you sure you want to cancel subscription for':
    'Oletko varma, että haluat peruuttaa tilauksen',
  'Add parking terminal': 'Lisää pysäköintiautomaatti',
  'Invoicing reference': 'Laskutusviite',
  'Parking terminal': 'Pysäköintiautomaatti',
  'Edit parking terminal': 'Muokkaa pysäköintiautomaattia',
  'The invoicing reference field is required':
    'Laskutusviitekenttä on pakollinen',
  'Are you sure you want to install parking terminal? yes/no':
    'Oletko varma, että haluat asentaa pysäköintiautomaatin? kyllä/ei',
  'Are you sure you want to uninstall parking terminal? yes/no':
    'Oletko varma, että haluat poistaa pysäköintiautomaatin? kyllä/ei',
  'Add subscription': 'Lisää tilaus',
  'Add car park areas': 'Lisää pysäköintialueita',
  'Add to subscription': 'Lisää tilaukseen',
  'Create subscription': 'Luo tilaus',
  'Are you sure you want to remove': 'Oletko varma, että haluat poistaa',
  subscription: 'tilaus',
  VAT: 'ALV',
  'Parking areas': 'Pysäköintialueet',
  Publish: 'Julkaise',
  'Cancel subscription': 'Peruuta tilaus',
  'Remove subscription': 'Poista tilaus',
  'Make private': 'Tee yksityiseksi',
  'Make public': 'Tee julkiseksi',
  'Save subscription': 'Tallenna tilaus',
  remove: 'poista',
  cancel: 'peruuta',
  'Invoice reference': 'Laskutusviite',
  'Published at': 'Julkaistu',
  Accessibility: 'Esteettömyys',
  'Free parking lists': 'Ilmaiset pysäköintilistat',
  'All car parks': 'Kaikki parkkipaikat',
  'Choose car parks': 'Valitse pysäköintialueet',
  'car parks': 'pysäköintialueet',
  'Car parks': 'Pysäköintialueet',
};

export default fiJSON;
